import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { MyContext } from '../../MyContext';
import {
  FaFacebookF,
  FaFileDownload,
  FaInstagram,
  FaPinterestP,
  FaShoppingBag,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import img from "../../img/vr-details.png";
import author1 from "../../img/4.jpg";
import author2 from "../../img/5.jpg";
import '../../i18n';
import { useTranslation } from 'react-i18next';

import "./style.css";

function ProductsDetails(props) {


  const { myValue, setMyValue } = useContext(MyContext);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(myValue); // change la langue à la langue du navigateur
  }, []); // exécute une fois après le premier rendu du composant

  const onClick = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <section className="fag-product-details section_100">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="product-details-image">
                <img src={img} alt="product" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="product-details-text">
                <h3>{t('Réalité Virtuelle')}</h3>
                {/* <div className="tour-rating">
                  <ul>
                    <li>
                      <AiFillStar />
                    </li>
                    <li>
                      <AiFillStar />
                    </li>
                    <li>
                      <AiFillStar />
                    </li>
                    <li>
                      <AiFillStar />
                    </li>
                    <li>
                      <AiFillStar />
                    </li>
                  </ul>
                  <p>(23 rating)</p>
                </div> */}
                <div className="single-pro-page-para">
                  <p>
                    {t('Jouer à la version PC et immersion')}
                  </p>
                </div>
                <div className="single-shop-price">
                <p>
                
                  {t('Démonstration vidéo')}:<span><a style={{color:"#ff7a21"}} target="_blank" href="https://youtu.be/MsEFZvNlFNg">cliquer ici</a></span>
                  </p>
                  <p>
                    Meta Quest Pro:<span><a style={{color:"#ff7a21"}} target="_blank" href="https://www.meta.com/fr/en/quest/quest-pro/">https://www.meta.com/fr/en/quest/quest-pro/</a></span>
                  </p>
                  <p>
                    SPEKTRUM:<span>SPMWS2000</span>
                  </p>
                  <p>
                    Amazon:<span><a style={{color:"#ff7a21"}} target="_blank" href="https://amzn.eu/d/7AwY9n9">https://amzn.eu/d/7AwY9n9</a></span>
                  </p>
                  
                </div>
                {/* <div className="quantity">
                  <p>Quantity: </p>
                  <div className="num-block skin-2">
                    <div className="num-in">
                      <input
                        type="number"
                        className="in-num"
                        defaultValue={1}
                        readOnly=""
                      />
                    </div>
                  </div>
                </div> */}
                <div className="single-shop-page-btn">
                <a className="fag-btn" href="https://drive.google.com/file/d/11DtFQX4IapnEjkKZhtfXyr8hFTdkLb3o/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
               
                  
                 
                    <FaFileDownload /> {t('Télechargement')} <span />
                   
           
                  </a>
                  <ul>
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaTwitter />
                      </Link>
                    </li> */}
                    <li>
                    <a href="https://www.instagram.com/drone_pratice" target="_blank" rel="noopener noreferrer">
                          <FaInstagram />
                        </a>
                    </li>
                    <li>
                    <a href="https://www.youtube.com/channel/UC_SBzP3L_2lVstXNmvVgpew" target="_blank" rel="noopener noreferrer">
                          <FaYoutube />
                          </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="product-panel-list">
                <Tabs
                  defaultActiveKey="description"
                  id="uncontrolled-tab-example"
                  className="product-tab"
                >
                  <Tab eventKey="description" title="Presentation">
                    <div className="tab-gamess-details">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab-body">
                            <h3> {t('Simuation FPV avec des lunettes VR')}</h3>
                            <p>
                            {t('Le simulateur de pilotage de drone que nous vous proposons')}
                            </p>
                            <p>
                            {t('Notre programme de formation est structuré')}
                            </p>
                            <p>
                              {t('Pour utiliser une radiocommande SPEKTRUM')}
                            </p>
                            <p>
                            {t('Si vous rencontrez')}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End Row */}
                    </div>
                  </Tab>
                  {/* <Tab eventKey="reviews" title="reviews">
                    <div className="tab-gamess-details">
                      <Row>
                        <Col md={12}>
                          <div className="tab-body">
                            <div className="fag-comment-list">
                              <div className="single-comment-item">
                                <div className="single-comment-box">
                                  <div className="main-comment">
                                    <div className="author-image">
                                      <img src={author1} alt="author" />
                                    </div>
                                    <div className="comment-text">
                                      <div className="comment-info">
                                        <h4>david kamal</h4>
                                        <ul>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                        </ul>
                                        <p>4 minitues ago</p>
                                      </div>
                                      <div className="comment-text-inner">
                                        <p>
                                          Ne erat velit invidunt his. Eum in
                                          dicta veniam interesset, harum lupta
                                          definitionem. Vocibus suscipit
                                          prodesset vim ei, equidem perpetua eu
                                          per.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="single-comment-box comment_reply">
                                  <div className="main-comment">
                                    <div className="author-image">
                                      <img src={author2} alt="author" />
                                    </div>
                                    <div className="comment-text">
                                      <div className="comment-info">
                                        <h4>Jerix Ablin</h4>
                                        <ul>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                          <li>
                                            <AiFillStar />
                                          </li>
                                        </ul>
                                        <p>12 minitues ago</p>
                                      </div>
                                      <div className="comment-text-inner">
                                        <p>
                                          orem ipsum dolor sit amet, consectetur
                                          adipisicing elit. Velit omnis animi et
                                          iure laudantium vitae, praesentium
                                          optio, sapiente distinctio illo?{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                           
                            <div className="fag-leave-comment">
                              <form>
                                <Row>
                                  <Col lg={12}>
                                    <div className="comment-field">
                                      <textarea
                                        className="comment"
                                        placeholder="Comment..."
                                        name="comment"
                                        defaultValue={""}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <div className="comment-field">
                                      <button type="submit" className="fag-btn">
                                        Add Comment <span />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          
                          </div>
                        </Col>
                      </Row>
                   
                    </div>
                  </Tab> */}
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ProductsDetails;
