import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./main-component/App/App";
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Helmet>
      <title>Drone Simulator - Learn to pilot a drone</title>
      <meta name="description" content="A drone simulator application to practice piloting drones with realistic exercises." />
      <meta name="keywords" content="Drone, Simulator, Practice, Piloting, Realistic, Exercises" />
    </Helmet>
    <App />
  </React.StrictMode>
);
