import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import AllRoute from "../router";
import "bootstrap/dist/css/bootstrap.min.css";
// import Cursor from "../../components/Cursor";
import "./App.css";
import { MyContext } from '../../MyContext' // Assurez-vous que le chemin est correct

//ReactGA.initialize('GTM-PSRR4CD7'); // Remplacez par votre ID de suivi


const App = () => {
  const browserLang = navigator.language || navigator.userLanguage; 
  const [myValue, setMyValue] = useState(browserLang);
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <MyContext.Provider value={{ myValue, setMyValue }}>
    <div className="App main-wrapper">
      {/* <Cursor /> */}
     
         <AllRoute />
      
    </div>
    </MyContext.Provider>
  );
};

export default App;
