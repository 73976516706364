import React from "react";

import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import VersionPc from "../../components/VersionPC";
import Footer from "../../components/Footer";

const VersionPcPage = () => {
  return (
    <>
      <Header />
      {/* <PageTitle Title={<>Version jeux PC</>} /> */}
      <VersionPc />
      <Footer />
    </>
  );
};
export default VersionPcPage;
