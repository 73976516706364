import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import game1 from "../../img/stage1.jpg";
import game2 from "../../img/stage2.jpg";
import game3 from "../../img/stage3.jpg";
import game4 from "../../img/stage4.jpg";
import game5 from "../../img/stage5.jpg";
import game6 from "../../img/stage6.jpg";
import game7 from "../../img/stage7.jpg";
import game8 from "../../img/stage8.jpg";
import game9 from "../../img/stage9.jpg";
import game10 from "../../img/stage10.jpg";
import game11 from "../../img/stage11.jpg";
import game12 from "../../img/stage12.jpg";
import { MyContext } from '../../MyContext';
import "./style.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import Cookies from 'js-cookie';
import '../../i18n';
import { useTranslation } from 'react-i18next';

function Games(props) {

  const { myValue, setMyValue } = useContext(MyContext);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(myValue); // change la langue à la langue du navigateur
  }, []); // exécute une fois après le premier rendu du composant


  const [urlYoutube, setUrlYoutube] = useState("https://www.youtube.com/embed/6lNVYNszvaI"); 

  const [stage1a, setStage1a] = useState(parseInt(localStorage.getItem('1a')));
  const [stage2a, setStage2a] = useState(parseInt(localStorage.getItem('2a')));
  const [stage3a, setStage3a] = useState(parseInt(localStorage.getItem('3a')));
  const [stage4a, setStage4a] = useState(parseInt(localStorage.getItem('4a')));
  const [stage5a, setStage5a] = useState(parseInt(localStorage.getItem('5a')));
  const [stage6a, setStage6a] = useState(parseInt(localStorage.getItem('6a')));
  const [stage7a, setStage7a] = useState(parseInt(localStorage.getItem('7a')));
  const [stage8a, setStage8a] = useState(parseInt(localStorage.getItem('8a')));
  const [stage9a, setStage9a] = useState(parseInt(localStorage.getItem('9a')));
  const [stage10a, setStage10a] = useState(parseInt(localStorage.getItem('10a')));
  const [stage11a, setStage11a] = useState(parseInt(localStorage.getItem('11a')));
  const [stage12a, setStage12a] = useState(parseInt(localStorage.getItem('12a')));

  const [stage1b, setStage1b] = useState(parseInt(localStorage.getItem('1b')));
  const [stage2b, setStage2b] = useState(parseInt(localStorage.getItem('2b')));
  const [stage3b, setStage3b] = useState(parseInt(localStorage.getItem('3b')));
  const [stage4b, setStage4b] = useState(parseInt(localStorage.getItem('4b')));
  const [stage5b, setStage5b] = useState(parseInt(localStorage.getItem('5b')));
  const [stage6b, setStage6b] = useState(parseInt(localStorage.getItem('6b')));
  const [stage7b, setStage7b] = useState(parseInt(localStorage.getItem('7b')));
  const [stage8b, setStage8b] = useState(parseInt(localStorage.getItem('8b')));
  const [stage9b, setStage9b] = useState(parseInt(localStorage.getItem('9b')));
  const [stage10b, setStage10b] = useState(parseInt(localStorage.getItem('10b')));
  const [stage11b, setStage11b] = useState(parseInt(localStorage.getItem('11b')));
  const [stage12b, setStage12b] = useState(parseInt(localStorage.getItem('12b')));

  const [stage1c, setStage1c] = useState(parseInt(localStorage.getItem('1c')));
  const [stage2c, setStage2c] = useState(parseInt(localStorage.getItem('2c')));
  const [stage3c, setStage3c] = useState(parseInt(localStorage.getItem('3c')));
  const [stage4c, setStage4c] = useState(parseInt(localStorage.getItem('4c')));
  const [stage5c, setStage5c] = useState(parseInt(localStorage.getItem('5c')));
  const [stage6c, setStage6c] = useState(parseInt(localStorage.getItem('6c')));
  const [stage7c, setStage7c] = useState(parseInt(localStorage.getItem('7c')));
  const [stage8c, setStage8c] = useState(parseInt(localStorage.getItem('8c')));
  const [stage9c, setStage9c] = useState(parseInt(localStorage.getItem('9c')));
  const [stage10c, setStage10c] = useState(parseInt(localStorage.getItem('10c')));
  const [stage11c, setStage11c] = useState(parseInt(localStorage.getItem('11c')));
  const [stage12c, setStage12c] = useState(parseInt(localStorage.getItem('12c')));

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [stage, setStage] = useState("1a");


  const closePopup = () => {

    //console.log("STAGE1: ",parseInt(localStorage.getItem('stage1')));

    setStage1a(parseInt(localStorage.getItem('stage1a')));
    setStage2a(parseInt(localStorage.getItem('2a')));
    setStage3a(parseInt(localStorage.getItem('3a')));
    setStage4a(parseInt(localStorage.getItem('4a')));
    setStage5a(parseInt(localStorage.getItem('5a')));
    setStage6a(parseInt(localStorage.getItem('6a')));
    setStage7a(parseInt(localStorage.getItem('7a')));
    setStage8a(parseInt(localStorage.getItem('8a')));
    setStage9a(parseInt(localStorage.getItem('9a')));
    setStage10a(parseInt(localStorage.getItem('10a')));
    setStage11a(parseInt(localStorage.getItem('11a')));
    setStage12a(parseInt(localStorage.getItem('12a')));
    setStage1b(parseInt(localStorage.getItem('1b')));
    setStage2b(parseInt(localStorage.getItem('2b')));
    setStage3b(parseInt(localStorage.getItem('3b')));
    setStage4b(parseInt(localStorage.getItem('4b')));
    setStage5b(parseInt(localStorage.getItem('5b')));
    setStage6b(parseInt(localStorage.getItem('6b')));
    setStage7b(parseInt(localStorage.getItem('7b')));
    setStage8b(parseInt(localStorage.getItem('8b')));
    setStage9b(parseInt(localStorage.getItem('9b')));
    setStage10b(parseInt(localStorage.getItem('10b')));
    setStage11b(parseInt(localStorage.getItem('11b')));
    setStage12b(parseInt(localStorage.getItem('12b')));
    setStage1c(parseInt(localStorage.getItem('1c')));
    setStage2c(parseInt(localStorage.getItem('2c')));
    setStage3c(parseInt(localStorage.getItem('3c')));
    setStage4c(parseInt(localStorage.getItem('4c')));
    setStage5c(parseInt(localStorage.getItem('5c')));
    setStage6c(parseInt(localStorage.getItem('6c')));
    setStage7c(parseInt(localStorage.getItem('7c')));
    setStage8c(parseInt(localStorage.getItem('8c')));
    setStage9c(parseInt(localStorage.getItem('9c')));
    setStage10c(parseInt(localStorage.getItem('10c')));
    setStage11c(parseInt(localStorage.getItem('11c')));
    setStage12c(parseInt(localStorage.getItem('12c')));
    setPopupVisible(false);
  };

  const openPopup = (stage) => {
    setStage(stage);
    setPopupVisible(true);
  };

  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(false);
  };
  const openModal = (url) => {
    setUrlYoutube(url);
    setModal(true);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  // const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
  //   loaderUrl: "../build/stage1.loader.js",
  //   dataUrl: "../build/stage1.data",
  //   frameworkUrl: "../build/stage1.framework.js",
  //   codeUrl: "../build/stage1.wasm",
  // });

  return (
    <>


      {isPopupVisible && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}>
          <button onClick={closePopup} style={{ position: 'absolute', right: 10, top: 10, zIndex: 10000 }}>
            <MdClose size={30} />
          </button>
          <iframe
            src={`https://dronepratice.com/game/index.html?stage=${stage}`}
            style={{ width: '100%', height: '100%' }}
            frameBorder="0"
          />
        </div>
      )}


      <section className="fag-games-area section_140">
        <Container>

          {/* <Unity
        style={{width:1000,height:600}}
        unityProvider={unityProvider}
       /> */}
          <Row>
            <Col sm={12}>
              <div className="site-heading">
                <h2 className="heading_animation">
                {t('VERSION')} <span>{t('WEB')}</span>
                </h2>
                <p>
                {t('Bienvenue sur notre site')}
                </p>
                <p>
                 
                {t('Connectez une manette')}
                 
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                className="games-masonary"
              >
                <Nav className="projectFilter project-btn">
                  <Nav.Item>
                    <Nav.Link eventKey="first">{t('FACILE')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">NORMAL</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">{t('Difficile')}</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="clearfix gamesContainer">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {/* Mission 1a */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game1} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6lNVYNszvaI")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 1
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage1a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("1a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 2a */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game2} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6YOkdn1SbXw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 2
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage2a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("2a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 3a */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game3} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/11T9Muz1hUo")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 3
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage3a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("3a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 4 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game4} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/Np2mWtB7UIw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 4
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage4a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("4a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 5 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game5} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/jzUrVeYz4Bg")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 5
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage5a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("5a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 6 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game6} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/kHxCPaywVa0")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 6
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage6a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("6a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 7 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game7} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/oD0fvRhEqM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 7
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage7a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("7a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 8 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game8} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/sjjtXxcke8U")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 8
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage8a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("8a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 9 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game9} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/5gLyO9fdApk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 9
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage9a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("9a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 10 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game10} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/TlzN9bULEvk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 10
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage10a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("10a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                         {/* Mission 11 */}
                         <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game11} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/dn4DVNT67FM")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 11
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage11a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("11a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                         {/* Mission 12 */}
                         <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game12} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/2zSqWONUpM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 12
                                  </Link>
                                </h3>
                                <p className="game-meta">{t('Niveau Facile')}</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">GPS | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage12a >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12a >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12a >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12a >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12a >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("12a")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {/* Mission 1 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game1} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6lNVYNszvaI")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 1
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage1b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("1b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 2 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game2} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6YOkdn1SbXw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 2
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage2b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("2b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 3 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game3} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/11T9Muz1hUo")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 3
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage3b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("3b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 4 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game4} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/Np2mWtB7UIw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 4
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage4b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("4b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 5 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game5} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/jzUrVeYz4Bg")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 5
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage5b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("5b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 6 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game6} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/kHxCPaywVa0")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 6
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage6b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("6b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 7 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game7} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/oD0fvRhEqM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 7
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage7b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("7b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 8 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game8} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/sjjtXxcke8U")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 8
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage8b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("8b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 9 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game9} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/5gLyO9fdApk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 9
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage9b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("9b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 10 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game10} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/TlzN9bULEvk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 10
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage10b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("10b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 11 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game11} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/dn4DVNT67FM")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 11
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage11b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("11b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                         {/* Mission 12 */}
                         <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game12} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/2zSqWONUpM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 12
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau normal</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Null')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage12b >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12b >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12b >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12b >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12b >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("12b")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {/* Mission 1 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game1} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6lNVYNszvaI")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 1
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage1c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage1c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("1c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 2 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game2} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/6YOkdn1SbXw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 2
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage2c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage2c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("2c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 3 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game3} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/11T9Muz1hUo")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 3
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage3c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage3c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("3c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 4 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game4} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/Np2mWtB7UIw")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 4
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage4c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage4c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("4c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 5 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game5} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/jzUrVeYz4Bg")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 5
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage5c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage5c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("5c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 6 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game6} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/kHxCPaywVa0")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 6
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage6c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage6c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("6c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 7 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game7} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/oD0fvRhEqM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 7
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage7c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage7c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("7c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 8 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game8} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/sjjtXxcke8U")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 8
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage8c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage8c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("8c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 9 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game9} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/5gLyO9fdApk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 9
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage9c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage9c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("9c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 10 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game10} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/TlzN9bULEvk")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 10
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage10c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage10c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("10c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Mission 11 */}
                        <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game11} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/dn4DVNT67FM")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 11
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage11c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage11c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("11c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                         {/* Mission 12 */}
                         <Col lg={3} sm={6}>
                          <div className="games-item mobile">
                            <div className="games-single-item img-contain-isotope">
                              <div className="games-thumb">
                                <div className="games-thumb-image">
                                  <Link to="/">
                                    <img src={game12} alt="games" />
                                  </Link>
                                </div>
                                <div className="game-overlay">
                                  <Link
                                    to="/"
                                    onClick={() => openModal("https://www.youtube.com/embed/2zSqWONUpM4")}
                                    className="popup-youtube"
                                  >
                                    <span>
                                      <FaPlay />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="games-desc">
                                <h3>
                                  <Link to="/game-single">
                                    Mission 12
                                  </Link>
                                </h3>
                                <p className="game-meta">Niveau difficile</p>
                                <p className="game-meta">Pilot | FPV | Gimbal</p>
                                <p className="game-meta">{t('Pas De GPS')} | {t('Vent Fort')}</p>
                                <p className="game-meta">
                                  {t('Temps')}:<span> 60s</span>
                                </p>
                                <div className="game-rating">
                                  {/* <h4>4.5</h4> */}
                                  <ul>
                                    <li>
                                      {stage12c >= 1 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12c >= 2 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12c >= 3 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12c >= 4 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                    <li>
                                      {stage12c >= 5 ? <AiFillStar /> : <AiOutlineStar />}
                                    </li>
                                  </ul>
                                </div>
                                <div className="game-action">
                                  <div className="game-buy">
                                    <button onClick={() => openPopup("12c")} className="fag-btn-outline">{t('LANCER LA MISSION')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
      {modal ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content">
              <span className="closeVideo" onClick={closeModal}>
                <MdClose />
              </span>
              <div className="modal__video-align">
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt className="modal__spinner-style" />
                  </div>
                ) : null}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="800"
                  height="500"
                  src={urlYoutube}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default Games;
