import React from "react";

import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import VersionVr from "../../components/VersionVR";
import Footer from "../../components/Footer";

const VersionVrPage = () => {
  return (
    <>
      <Header />
      {/* <PageTitle Title={<>Version jeux PC</>} /> */}
      <VersionVr />
      <Footer />
    </>
  );
};
export default VersionVrPage;
