import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Pour utiliser une radiocommande SPEKTRUM":"To use a SPEKTRUM remote control, you need the Doogle USB SPMWS2000.",
      "Notre programme de formation est structuré":"Our training program is structured into several stages, each tailored to a different skill level, allowing you to progress effectively and at your own pace. Whether you are a beginner or an experienced pilot, our stages will provide you with the skills and confidence needed to become an expert in drone piloting.",
      "Le simulateur de pilotage de drone que nous vous proposons":"The drone piloting simulator we offer provides total immersion thanks to the use of Meta Quest Pro glasses and a SPEKTRUM remote control. By combining these cutting-edge technologies, you can pilot in FPV (First Person View) mode, navigate, and learn to master drone piloting techniques.",
      "Simuation FPV avec des lunettes VR":"FPV Simulation with VR glasses",
      "Démonstration vidéo":"Video demonstration",
      "Jouer à la version PC et immersion":"Play the PC version for total immersion and pilot with a SPEKTRUM remote control for added realism.",
      "Réalité Virtuelle":"Virtual Reality with Meta Quest Pro",
      "Si vous rencontrez":"If you encounter any difficulties or have improvement ideas, let me know on the Instagram account.",
      "Pour utiliser une radiocommande":"To use a SPEKTRUM remote control, you need the USB Doogle SPMWS2000.",
      "Ce simulateur de vol pour drone":"This drone flight simulator allows you to practice piloting. You can connect a SPEKTRUM remote control for added realism.",
      "Jeux PC Windows":"Windows PC Games",
      "Télechargement":"Download",
      "cliquer ici":"click here",
      // Ajoutez vos chaînes de traduction en anglais ici
      "Démonstration vidéo":"Video demonstration",
      "Jouer à la version PC":"Play the PC version and use a SPEKTRUM remote control for added realism.",
      "Jeux PC":"PC games with remote control.",
      "Pas De GPS":"No GPS",
      "Vent Fort":"Strong Wind",
      "LANCER LA MISSION":"START MISSION",
      "Temps":"Time",
      "Vent Null":"Wind Null",
      "Niveau Facile":"EASY LEVEL",
      "Difficile":"HARD",
      "FACILE":"EASY",
      "Connectez une manette":"Connect an Xbox controller and start the game by selecting a level and a mission.",
      "Bienvenue sur notre site":"Welcome to our drone piloting training site! Discover our mini-games designed to help you learn and improve your piloting skills. Explore a variety of fun stages to perfect your technique. Start training now to become an expert in drone piloting!",
      "VERSION":"WEB",
      "WEB":"Version",
      "Regarder la vidéo":"Watch the video",
      "Simulateur Réaliste":"Realistic Simulator",
      "Version VR":"VR Version",
      "Version PC":"PC Version",
      "Version WEB":"WEB Version",
      "Pilotage de Drone":"Drone Piloting",
      "title": "Drone Simulator - Learn to pilot a drone",
      "description": "A drone simulator application to practice piloting drones with realistic exercises.",
      // ...
    }
  },
  fr: {
    translation: {
      // Ajoutez vos chaînes de traduction en français ici
      "Pour utiliser une radiocommande SPEKTRUM":"Pour utiliser une radiocommande SPEKTRUM il vous faut le Doogle USB SPMWS2000.",
      "Notre programme de formation est structuré":" Notre programme de formation est structuré en plusieurs stages, chacun adapté à un niveau de compétence différent, pour vous permettre de progresser de manière efficace et adaptée à votre rythme. Que vous soyez débutant ou pilote expérimenté, nos stages vous apporteront les compétences et la confiance nécessaires pour devenir un expert en pilotage de drone.",
      "Le simulateur de pilotage de drone que nous vous proposons":"Le simulateur de pilotage de drone que nous vous proposons offre une immersion totale grâce à l'utilisation des lunettes Meta Quest Pro et d'une radiocommande SPEKTRUM. En combinant ces technologies de pointe, vous pouvez piloter en mode FPV (First Person View), vous orienter et apprendre à maîtriser les techniques de pilotage de drones.",
      "Simuation FPV avec des lunettes VR":"Simuation FPV avec des lunettes VR",
      "Démonstration vidéo":"Démonstration vidéo",
      "Jouer à la version PC et immersion":"Jouer à la version PC et immersion totale et en pilotant avec une radiocommande SPEKTRUM pour plus de réalisme.",
      "Réalité Virtuelle":"Réalité Virtuelle avec Meta Quest Pro",
      "Si vous rencontrez":"Si vous rencontrez des difficultées ou si vous avez des idées d'amélioration, faites moi en part sur le compte Insta.",
      "Pour utiliser une radiocommande":"Pour utiliser une radiocommande SPEKTRUM il vous faut le Doogle USB SPMWS2000.",
      "Ce simulateur de vol pour drone":"Ce simulateur de vol pour drone permet de s'entrainer au pilotage. Vous pouvez connecter une radio commande SPEKTRUM pour plus de réalisme.",
      "Jeux PC Windows":"Jeux PC Windows",
      "Télechargement":"Télechargement",
      "cliquer ici":"cliquer ici",
      "Démonstration vidéo":"Démonstration vidéo",
      "Jouer à la version PC":"Jouer à la version PC et utiliser une radiocommande SPEKTRUM pour plus de réalisme.",
      "Jeux PC":"Jeux PC avec radiocommande",
      "Pas De GPS":"Pas De GPS",
      "Vent Fort":"Vent Fort",
      "LANCER LA MISSION":"LANCER LA MISSION",
      "Temps":"Temps",
      "Vent Null":"Vent Null",
      "Niveau Facile":"Niveau Facile",
      "Difficile":"Difficile",
      "FACILE":"FACILE",
      "Connectez une manette":"Connectez une manette Xbox et lancez le jeu en sélectionnant un niveau et une mission.",
      "Bienvenue sur notre site":"Bienvenue sur notre site d'entraînement au pilotage de drone ! Découvrez nos mini-jeux conçus pour vous aider à apprendre et à améliorer vos compétences de pilotage. Parcourez des stages variés et amusants pour perfectionner votre technique. Entrainez vous dès maintenant pour devenir un expert du pilotage de drone !",
      "VERSION":"VERSION",
      "WEB":"WEB",
      "Regarder la vidéo":"Regarder la vidéo",
      "Simulateur Réaliste":"Simulateur Réaliste",
      "Version VR":"Version VR",
      "Version PC":"Version PC",
      "Version WEB":"Version WEB",
      "Pilotage de Drone":"Pilotage de Drone",
      "title": "Simulateur de drone - Apprenez à piloter un drone",
      "description": "Une application de simulateur de drone pour pratiquer le pilotage de drones avec des exercices réalistes.",
      // ...
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // langue par défaut
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;